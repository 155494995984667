<template>
  <div class="box" style="height: calc(100vh - 110px);">
    <div class="p-3">
      <div class="flex flex-wrap my-3">
        <Dropdown
          v-for="(data, key) in colHeaders" :key="key"
          v-model="colHeaders[key]" :options="bodegas"
          optionLabel="name" optionValue="name"
          placeholder="seleccione"
          class="mx-2"
          @change="changeItem(data, key)"
          />
        <Button icon="pi pi-plus" v-tooltip.top="'Agregar Item'" class="p-button-rounded p-button-info mx-3 my-auto" @click="colHeaders.push(''), content[0].push('')" />
        {{ colHeaders }}
      </div>
      <hot-table :data="content" :rowHeaders="true" :colHeaders="colHeaders" :settings="settings" licensekey="00000-00000-00000-00000-00000"></hot-table>
      <Button label="Aceptar" icon="pi pi-save" class="p-button-primary m-3" />
    </div>
  </div>
</template>
<script>

import { ref } from 'vue'

export default {
  name: 'crearSolicitud',
  components: {},
  setup () {
    const settings = {
      licenseKey: 'non-commercial-and-evaluation'
    }
    const itemSelected = ref()
    const cantItems = ref(0)
    const bodegas = ref([
      { name: 'Nombre', code: 'NY' },
      { name: 'Codigo', code: 'RM' },
      { name: 'Lote', code: 'LDN' }
    ])
    const colHeaders = ref(['A'])
    const content = ref([
      ['', '', '', '', '', '', '']
      // ['2016', 10, 11, 12, 13],
      // ['2017', 20, 11, 14, 13],
      // ['2018', 30, 15, 12, 13]
    ])

    const changeItem = (data, key) => {
      console.log('data--', data, 'key-----', key)
      console.log('colHeaders---', colHeaders.value)
      // colHeaders.value[key] = data
    }

    const saveFormato = () => {
      // save all cell's data
        console.log('el body', content.value)
    }

    return {
      content,
      colHeaders,
      settings,
      itemSelected,
      cantItems,
      bodegas,
      changeItem,
      saveFormato
      // exportPlugin
    }
  }
}
</script>
<style lang="scss">
.bg-grad {
  background: transparent linear-gradient(1deg, #0080F9 0%, #00DCB1 100%) 0% 0% no-repeat padding-box;
}

.w-proceso {
  width: 30%;
}
</style>
